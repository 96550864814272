const common = {
    data() {
        return {
            info: {
                pdf_to_word: {
                    id: "1",
                    name: "PDF转Word",
                    des: "将您的PDF文件转换WORD文档",
                    upload_type: '.pdf',
                    link: "pdf_to_word",
                    img: require("@/assets/img/pdf_to_word.svg"),
                },
                pdf_to_excel: {
                    id: "2",
                    name: "PDF转EXCEL",
                    des: "将您的PDF文件转换为EXCEL表格",
                    upload_type: '.pdf',
                    link: "pdf_to_excel",
                    img: require("@/assets/img/pdf_to_excel.svg"),
                },
                pdf_to_ppt: {
                    id: "3",
                    name: "PDF转PPT",
                    des: "将您的PDF文件转换为PPT演示文件",
                    upload_type: '.pdf',
                    link: "pdf_to_ppt",
                    img: require("@/assets/img/pdf_to_ppt.svg"),
                },
                pdf_to_html: {
                    id: "4",
                    name: "PDF转HTML",
                    des: "将您的PDF文件转换为HTML网页",
                    upload_type: '.pdf',
                    link: "pdf_to_html",
                    img: require("@/assets/img/pdf_to_html.svg"),
                },
                pdf_to_img: {
                    id: "5",
                    name: "PDF转图片",
                    des: "将您的PDF文件转换为图片文件",
                    upload_type: '.pdf',
                    link: "pdf_to_img",
                    img: require("@/assets/img/pdf_to_img.svg"),
                },
                pdf_to_txt: {
                    id: "6",
                    name: "PDF转TXT",
                    des: "将您的PDF文件转换为TXT文件",
                    upload_type: '.pdf',
                    link: "pdf_to_txt",
                    img: require("@/assets/img/pdf_to_txt.svg"),
                },
                word_to_pdf: {
                    id: "7",
                    name: "WORD转PDF",
                    des: "将您的WORD文档转换为PDF文件",
                    upload_type: '.docx,.docx',
                    link: "word_to_pdf",
                    img: require("@/assets/img/word_to_pdf.svg"),
                },
                excel_to_pdf: {
                    id: "8",
                    name: "EXCEL转PDF",
                    des: "将您的EXCEL表格转换为PDF文件",
                    upload_type: '.xls,.xlsx',
                    link: "excel_to_pdf",
                    img: require("@/assets/img/excel_to_pdf.svg"),
                },
                ppt_to_pdf: {
                    id: "9",
                    name: "PPT转PDF",
                    des: "将您的PPT演示转换为PDF文件",
                    upload_type: '.ppt,.pptx',
                    link: "ppt_to_pdf",
                    img: require("@/assets/img/ppt_to_pdf.svg"),
                },
                html_to_pdf: {
                    id: "10",
                    name: "HTML转PDF",
                    des: "将您的HTML网页转换为PDF文件",
                    upload_type: '.html',
                    link: "html_to_pdf",
                    img: require("@/assets/img/html_to_pdf.png"),
                },
                img_to_pdf: {
                    id: "11",
                    name: "图片转PDF",
                    des: "将您的图片文件转换为PDF文件",
                    upload_type: '.png,.jpg,.bmp',
                    img: require("@/assets/img/img_to_pdf.svg"),
                },
                txt_to_pdf: {
                    id: "12",
                    name: "TXT转PDF",
                    des: "将您的TXT文件转换为PDF文件",
                    upload_type: '.txt',
                    link: "txt_to_pdf",
                    img: require("@/assets/img/txt_to_pdf.svg"),
                },
                lock_pdf: {
                    id: "13",
                    name: "PDF加密",
                    des: "用密码加密您的PDF",
                    upload_type: '.pdf',
                    link: "lock_pdf",
                    img: require("@/assets/img/lock_pdf.svg"),
                },
                unlock_pdf: {
                    id: "14",
                    name: "PDF解密",
                    des: "删除PDF密码",
                    upload_type: '.pdf',
                    link: "unlock_pdf",
                    img: require("@/assets/img/unlock_pdf.svg"),
                },
                logo_pdf: {
                    id: "15",
                    name: "PDF加水印",
                    des: "给您的PDF文件添加水印",
                    upload_type: '.pdf',
                    link: "logo_pdf",
                    img: require("@/assets/img/logo_pdf.png"),
                },
                unlogo_pdf: {
                    id: "16",
                    name: "PDF去水印",
                    des: "去除您PDF文件的水印",
                    upload_type: '.pdf',
                    link: "unlogo_pdf",
                    img: require("@/assets/img/unlogo_pdf.png"),
                },
                reset_pdf: {
                    id: "17",
                    name: "PDF重置密码",
                    des: "重置您的PDF密码",
                    upload_type: '.pdf',
                    link: "reset_pdf",
                    img: require("@/assets/img/reset_pdf.png"),
                },
                merge_pdf: {
                    id: "18",
                    name: "PDF合并",
                    des: "合并PDF文件",
                    upload_type: '.pdf',
                    multiple: true,
                    link: "merge_pdf",
                    img: require("@/assets/img/merge_pdf.svg"),
                },
                split_pdf: {
                    id: "19",
                    name: "PDF拆分",
                    des: "将PDF文件拆分成多个PDF文件",
                    upload_type: '.pdf',
                    link: "split_pdf",
                    img: require("@/assets/img/split_pdf.svg"),
                },
                zip_pdf: {
                    id: "20",
                    name: "PDF压缩",
                    des: "最简单减少PDF文件大小的方法",
                    upload_type: '.pdf',
                    link: "zip_pdf",
                    img: require("@/assets/img/zip_pdf.svg"),
                },
                rotate_pdf: {
                    id: "21",
                    name: "PDF旋转",
                    des: "旋转您的PDF文件方向",
                    upload_type: '.pdf',
                    link: "rotate_pdf",
                    img: require("@/assets/img/rotate_pdf.svg"),
                },
                pdf_replace_str: {
                    id: "22",
                    name: "PDF文字替换",
                    des: "查找并替换您PDF中的指定文字",
                    upload_type: '.pdf',
                    link: "pdf_replace_str",
                    img: require("@/assets/img/pdf_replace_str.svg"),
                },
                del_str_pdf: {
                    id: "23",
                    name: "PDF文字删除",
                    des: "删除您PDF中的指定文字",
                    upload_type: '.pdf',
                    link: "del_str_pdf",
                    img: require("@/assets/img/del_str_pdf.png"),
                },
                pdf_extract_img: {
                    id: "24",
                    name: "PDF图片提取",
                    des: "提取包含在PDF中的所有图片",
                    upload_type: '.pdf',
                    link: "pdf_extract_img",
                    img: require("@/assets/img/pdf_extract_img.svg"),
                },
                del_pdf: {
                    id: "25",
                    name: "PDF页面删除",
                    des: "删除PDF中的指定页码",
                    upload_type: '.pdf',
                    link: "del_pdf",
                    img: require("@/assets/img/del_pdf.svg"),
                },
                pages_pdf: {
                    id: "26",
                    name: "PDF添加页码",
                    des: "在线给PDF添加页码",
                    upload_type: '.pdf',
                    link: "pages_pdf",
                    img: require("@/assets/img/pages_pdf.png"),
                },
                title_pdf: {
                    id: "27",
                    name: "PDF添加页眉",
                    des: "在线给PDF添加页眉",
                    upload_type: '.pdf',
                    link: "title_pdf",
                    img: require("@/assets/img/title_pdf.svg"),
                },
                word_str_replace: {
                    id: "28",
                    name: "WORD文字替换",
                    des: "给您的WORD文档替换指定的文字",
                    upload_type: '.dox,.docx',
                    link: "word_str_replace",
                    img: require("@/assets/img/word_str_replace.png"),
                },
                word_del_str: {
                    id: "29",
                    name: "WORD文字删除",
                    des: "查找您的WORD文档并删除指定文字",
                    upload_type: '.dox,.docx',
                    link: "word_del_str",
                    img: require("@/assets/img/word_del_str.png"),
                },
                img_sumiao: {
                    id: "30",
                    name: "图片转素描",
                    des: "在线将图片模拟成素描画",
                    upload_type: '.png,.jpg,.bmp',
                    link: "img_sumiao",
                    img: require("@/assets/img/img_sumiao.png"),
                },
                img_dongman: {
                    id: "31",
                    name: "图片转漫画",
                    des: "在线将图片模拟成漫画",
                    upload_type: '.png,.jpg,.bmp',
                    link: "img_dongman",
                    img: require("@/assets/img/img_dongman.png"),
                },
                img_changtu: {
                    id: "32",
                    name: "长图拼接",
                    des: "多张图片合成一张图片",
                    upload_type: '.png,.jpg,.bmp',
                    multiple: true,
                    link: "img_changtu",
                    img: require("@/assets/img/img_changtu.png"),
                },
                html_to_img: {
                    id: "33",
                    name: "HTML转图片",
                    des: "把HTML网页转为图片文件",
                    upload_type: '.html',
                    link: "html_to_img",
                    img: require("@/assets/img/html_to_img.png"),
                },
                img_logo: {
                    id: "34",
                    name: "图片加水印",
                    des: "给您的图片文件添加水印",
                    upload_type: '.png,.bmp,.jpg',
                    link: "img_logo",
                    img: require("@/assets/img/logo_pdf.png"),
                },
            },
        }
    },
    created() {
        if (!this.info[this.work_type]) {
            this.$router.replace("/");
        }
    },
    mounted() {
        document.title = `${this.website_name} - ${this.title}`;
    },
    updated() {
        document.title = `${this.website_name} - ${this.title}`;
    },
    computed: {
        title() {
            return this.info[this.work_type].name;
        },
        des() {
            return this.info[this.work_type].des;
        },
        url() {
            return this.info[this.work_type].img;
        },
        upload_type() {
            return this.info[this.work_type].upload_type;
        },
        multiple() {
            return this.info[this.work_type].multiple;
        },
    },
}

export default common